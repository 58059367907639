<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { companies } from "@/config/api/companies";
import { company_categories } from "@/config/api/company_categories";
import CompanyModal from "@/components/companies/company-modal";
import TradeLicenseModal from "@/components/companies/trade-license-modal";
/**
 * Company Component
 */
export default {
  page: {
    title: "Companies Table",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
    CompanyModal,
    TradeLicenseModal,
  },

  data() {
    return {
      title: "Companies",
      items: [
        {
          text: "",
          // to: { name: "" },
        },
        {
          text: "All Companies",
          active: true,
        },
      ],
      selectCategoryOptions: [],
      companiesData: [],
      selectedCompany: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      activeTab: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      fields: [
        { key: "legal_name", sortable: true, label: "Legal Name" },
        { key: "company_experties", sortable: true, label: "Category" },
        { key: "trade_license", sortable: true, label: "Trade License", thStyle: { width: "100px" } },
        { key: "company_email", sortable: true, label: "Email" },
        { key: "createdAt", sortable: true },
        { key: "is_featured", sortable: true, label: "Featured" },
        { key: "is_company_approved", sortable: true, label: "Status" },
        { key: "operating_cities", sortable: true, label: "Cities", thStyle: { width: "20%" } },
        { key: "action" },
      ],
    };
  },
  created() {
    this.loadData();
  },
  computed: {
    rows() {
      return this.totalRows;
    },

    computedFields() {
      if (this.activeTab == 1) {
        return this.fields.filter((field) => !field.show);
      }
      return this.fields;
    },
    deletedCompanies() {
      return this.companiesData.filter((email) => email.deletedAt != null);
    },
    activeCompanies() {
      return this.companiesData.filter((email) => email.deletedAt === null);
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.companiesData.length;
  },
  methods: {
    updatePage(page) {
      this.loadData(page);
    },

    approve(item) {
      console.log(item);
      const api = companies.approve;
      api.id = item._id;
      try {
        this.generateAPI(api).then(() => {
          this.$bvToast.toast("Company Approved successfully!", {
            title: "Company Approval",
            variant: "success",
            solid: true,
          });
          this.loadData();
        });
      } catch {
        this.$bvToast.toast("Failed to approve company!", {
          title: "Company Approval",
          variant: "danger",
          solid: true,
        });
      }
    },
    disapprove(item) {
      console.log(item);
      const api = companies.disapprove;
      api.id = item._id;
      try {
        this.generateAPI(api).then(() => {
          this.$bvToast.toast("Company has been disapproved!", {
            title: "Company Disapproval",
            variant: "success",
            solid: true,
          });
          this.loadData();
        });
      } catch {
        this.$bvToast.toast("Failed to disapprove company!", {
          title: "Company Disapproval",
          variant: "danger",
          solid: true,
        });
      }
    },
    onFiltered() {
      // Trigger pagination to update the number of buttons/pages due to filtering
      // this.totalRows = filteredItems.length;
      // this.currentPage = 1;
    },
    updatePageLimit() {
      this.loadData(1, null, this.perPage);
    },
    async loadData(page = 1, search = "") {
      const api = companies.get;
      api.params = { page: page, search: search, limit: this.perPage };
      this.generateAPI(api).then((res) => {
        this.companiesData = res.data.companies.docs;
        this.totalRows = res.data.companies.totalDocs;
      });

      try {
        const api = company_categories.get_sub;
        let res = await this.generateAPI(api);
        res.data.categories.forEach((element) => {
          this.selectCategoryOptions.push({
            label: element.name ? element.name : `no name - id(${element._id})`,
            value: element._id,
          });
        });
      } catch (error) {
        console.error(error);
      }
    },
    showActionModal() {
      this.$bvModal.show("action-Company");
    },
    hideActionModal() {
      this.$bvModal.hide("action-Company");
    },
    editCompany(data) {
      this.selectedCompany = data;
      this.showActionModal();
    },
    navigate(path, id) {
      this.$router.push({
        path: path,
        query: { id: id },
      });
    },
    onRowClicked(item) {
      this.$router.push({
        path: "/company-info",
        query: { id: item._id },
      });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body pt-0">
            <b-button id="addCompany" @click="showActionModal" pill variant="primary">Add Company</b-button>

            <b-tabs nav-class="nav-tabs-custom" v-model="activeTab">
              <b-tab title-link-class="p-3">
                <template v-slot:title>
                  <a class="font-weight-bold active">All Companies</a>
                </template>
                <div class="row mt-4">
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Show&nbsp;
                        <b-form-select v-model="perPage" size="sm" :options="pageOptions" @change="updatePageLimit">
                        </b-form-select>&nbsp;entries
                      </label>
                    </div>
                  </div>
                  <!-- Search -->
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                      <label class="d-inline-flex align-items-center">
                        Search:
                        <b-form-input v-model="filter" type="search" class="form-control form-control-sm ml-2">
                        </b-form-input>
                      </label>
                    </div>
                  </div>
                  <!-- End search -->
                </div>

                <div class="table-responsive">
                  <b-table tbody-tr-class="rowClass" class="table-centered" hover :items="companiesData"
                    :fields="fields" responsive="sm" :busy="$store.state.api.loading" :current-page="currentPage"
                    :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter"
                    :filter-included-fields="filterOn" @filtered="onFiltered" @row-clicked="onRowClicked">
                    <template #table-busy>
                      <div style="text-align: center" class="mt-5 mb-5">
                        <pulse-loader color="#505d69" :loading="true"></pulse-loader>
                      </div>
                    </template>

                    <template v-slot:cell(trade_license)="row">
                      <TradeLicenseModal :data="row.item" />

                      <span class="badge badge-danger float-right"
                        v-if="row.item.trade_license_expiry_date && moment(row.item.trade_license_expiry_date).isBefore(moment())">Expired</span>
                    </template>
                    <template v-slot:cell(operating_cities)="row">
                      <div>
                        <a v-for="city, index in row.item.operating_cities" :key="index" style="cursor: pointer">
                          {{ city.name }} {{ index == row.item.operating_cities.length - 1 ?
                          "" : "|"
                          }}
                        </a>
                      </div>
                    </template>

                    <template v-slot:cell(company_experties)="row">
                      <div v-if="
                        row.item.company_experties &&
                        row.item.company_experties.length
                      ">
                        {{ row.item.company_experties[0].main_category.name }}
                      </div>
                      <div v-else>No Company Category</div>
                    </template>

                    <template v-slot:cell(display_name)="row">
                      {{ row.item.display_name }}
                    </template>
                    <template v-slot:cell(is_featured)="row">
                      {{ row.item.is_featured ? "Yes" : "No" }}
                    </template>
                    <template v-slot:cell(is_verified)="row">
                      {{ row.item.is_verified ? "Yes" : "No" }}
                    </template>
                    <template #cell(createdAt)="row">
                      {{ moment(row.item.createdAt).format("l") }}
                    </template>
                    <template #cell(deletedAt)="row" v-if="deletedCompanies">
                      {{
                      !row.item.deletedAt
                      ? ""
                      : moment(row.item.deletedAt).format("l")

                      }}
                    </template>
                    <template #cell(is_company_approved)="row">
                      {{
                      !!row.item.is_company_approved
                      ? "Approved"
                      : "Not Approved"

                      }}
                    </template>
                    <template v-slot:cell(action)="row">
                      <b-dropdown>
                        <template v-slot:button-content>
                          Actions
                          <i class="mdi mdi-chevron-down"></i>
                        </template>
                        <b-dropdown-item @click="editCompany(row.item)">Edit</b-dropdown-item>
                        <b-dropdown-item @click="approve(row.item)">Approve</b-dropdown-item>
                        <b-dropdown-item @click="disapprove(row.item)">Reject</b-dropdown-item>
                      </b-dropdown>
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-right">
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" @change="updatePage">
                        </b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tab>
              <b-tab title-link-class="p-3">
                <template v-slot:title>
                  <a class="font-weight-bold active">Active Companies</a>
                </template>
                <div class="row mt-4">
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Show&nbsp;
                        <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                      </label>
                    </div>
                  </div>
                  <!-- Search -->
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                      <label class="d-inline-flex align-items-center">
                        Search:
                        <b-form-input v-model="filter" type="search" class="form-control form-control-sm ml-2">
                        </b-form-input>
                      </label>
                    </div>
                  </div>
                  <!-- End search -->
                </div>

                <div class="table-responsive">
                  <b-table tbody-tr-class="rowClass" class="table-centered" :items="activeCompanies"
                    :fields="computedFields" responsive="sm" :busy="$store.state.api.loading" :per-page="perPage"
                    :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter"
                    :filter-included-fields="filterOn" @filtered="onFiltered" @row-clicked="onRowClicked">
                    <template #table-busy>
                      <div style="text-align: center" class="mt-5 mb-5">
                        <pulse-loader color="#505d69" :loading="true"></pulse-loader>
                      </div>
                    </template>
                    <template v-slot:cell(company_experties)="row">
                      <div v-if="
                        row.item.company_experties &&
                        row.item.company_experties.length
                      ">
                        {{ row.item.company_experties[0].main_category.name }}
                      </div>
                      <div v-else>No Company Category</div>
                    </template>
                    <template v-slot:cell(display_name)="row">
                      {{ row.item.display_name }}
                    </template>
                    <template v-slot:cell(trade_license)="row">
                      {{row.item.trade_license_expiry_date? moment(row.item.trade_license_expiry_date ).format("LL") :
                      "No date" }}

                      <span class="badge badge-danger float-right"
                        v-if="row.item.trade_license_expiry_date && moment(row.item.trade_license_expiry_date).isBefore(moment())">Expired</span>
                    </template>
                    <template v-slot:cell(is_featured)="row">
                      {{ row.item.is_featured ? "Yes" : "No" }}
                    </template>
                    <template v-slot:cell(is_verified)="row">
                      {{ row.item.is_verified ? "Yes" : "No" }}
                    </template>
                    <template #cell(createdAt)="row">
                      {{ moment(row.item.createdAt).format("l") }}
                    </template>
                    <template #cell(deletedAt)="row" v-if="deletedCompanies">
                      {{
                      !row.item.deletedAt
                      ? ""
                      : moment(row.item.deletedAt).format("l")

                      }}
                    </template>
                    <template v-slot:cell(operating_cities)="row">
                      <div>
                        <a v-for="city, index in row.item.operating_cities" :key="index" style="cursor: pointer">
                          {{ city.name }} {{ index == row.item.operating_cities.length - 1 ?
                          "" : "|"
                          }}
                        </a>
                      </div>
                    </template>
                    <template v-slot:cell(action)="row">
                      <b-dropdown>
                        <template v-slot:button-content>
                          Actions
                          <i class="mdi mdi-chevron-down"></i>
                        </template>
                        <b-dropdown-item @click="editCompany(row.item)">Edit</b-dropdown-item>
                        <b-dropdown-item @click="approve(row.item)">Approve</b-dropdown-item>
                        <b-dropdown-item @click="disapprove(row.item)">Reject</b-dropdown-item>
                      </b-dropdown>
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-right">
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tab>
              <b-tab title-link-class="p-3">
                <template v-slot:title>
                  <a class="font-weight-bold active">Deleted Companies</a>
                </template>
                <div class="row mt-4">
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Show&nbsp;
                        <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                      </label>
                    </div>
                  </div>
                  <!-- Search -->
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                      <label class="d-inline-flex align-items-center">
                        Search:
                        <b-form-input v-model="filter" type="search" class="form-control form-control-sm ml-2">
                        </b-form-input>
                      </label>
                    </div>
                  </div>
                  <!-- End search -->
                </div>

                <div class="table-responsive">
                  <b-table tbody-tr-class="rowClass" class="table-centered" :items="deletedCompanies" :fields="fields"
                    responsive="sm" :busy="$store.state.api.loading" :per-page="perPage" :current-page="currentPage"
                    :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter"
                    :filter-included-fields="filterOn" @filtered="onFiltered" @row-clicked="onRowClicked">
                    <template #table-busy>
                      <div style="text-align: center" class="mt-5 mb-5">
                        <pulse-loader color="#505d69" :loading="true"></pulse-loader>
                      </div>
                    </template>
                    <template v-slot:cell(company_experties)="row">
                      <div v-if="
                        row.item.company_experties &&
                        row.item.company_experties.length
                      ">
                        {{ row.item.company_experties[0].main_category.name }}
                      </div>
                      <div v-else>No Company Category</div>
                    </template>
                    <template v-slot:cell(display_name)="row">
                      {{ row.item.display_name }}
                    </template>
                    <template v-slot:cell(is_featured)="row">
                      {{ row.item.is_featured ? "Yes" : "No" }}
                    </template>
                    <template v-slot:cell(is_verified)="row">
                      {{ row.item.is_verified ? "Yes" : "No" }}
                    </template>
                    <template #cell(createdAt)="row">
                      {{ moment(row.item.createdAt).format("l") }}
                    </template>
                    <template #cell(deletedAt)="row" v-if="deletedCompanies">
                      {{
                      !row.item.deletedAt
                      ? ""
                      : moment(row.item.deletedAt).format("l")

                      }}
                    </template>
                    <template v-slot:cell(operating_cities)="row">
                      <div>
                        <a v-for="city, index in row.item.operating_cities" :key="index" style="cursor: pointer">
                          {{ city.name }} {{ index == row.item.operating_cities.length - 1 ?
                          "" : "|"
                          }}
                        </a>
                      </div>
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-right">
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
    <CompanyModal :selectedCompany="selectedCompany" v-on:resetModal="selectedCompany = null" v-on:reloadData="loadData"
      v-on:closeModal="hideActionModal" :selectCategoryOptions="selectCategoryOptions" />
  </Layout>
</template>

<style scoped>
#addCompany {
  float: right;
  margin-top: 10px;
}
</style>
